.container-detalle {
    min-height: 575px;
    margin-top: 4vh;
    display: grid;
    grid-template-columns: 3.5fr 1fr;
    gap: 15px;
}

.detalle-sucursales {
    height: 100%;
}

.v-linea {
    width: 1px;
    height: 100%;
    background-color: rgba(229, 229, 229, 1);
    margin: 0px 12px;
}

.container-detalle > .detalle-producto {
    background-color: #FFFFFF;
    border-radius: 25px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 4rem;
    padding: 30px;
    min-height: 590px;
}

/*  Producto imagen*/
.container-detalle > .detalle-producto > .producto-imagen {
    width: 200px;
    height: 200px;
    margin: 10px auto;
    margin-top: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container-detalle > .detalle-producto > .producto-imagen > div > img {
    width: auto; /* or max-width: 100%; */
    height: 100%;
}


/* Producto informacion*/
.container-detalle > .detalle-producto > .producto-informacion {
    padding-right: 30px;
    max-width: 429px;
}

.container-detalle > .detalle-producto > .producto-informacion > .imagen {
    display: flex;
    height: 30px;
    cursor: pointer;
    margin-bottom: 12px;
}

.container-detalle > .detalle-producto > .producto-informacion > .imagen > img{
    display: flex;
    height: 100%;
    width: auto;
    cursor: pointer;
    margin-bottom: 12px;
}

.container-detalle > .detalle-producto > .producto-informacion > p {
    font-size: 13px;
    font-weight: 500;
    margin: 10px 0px;
    line-height: normal;
    max-width: 355px;
    cursor:default;
}

.container-detalle > .detalle-producto > .producto-informacion > .producto-precio {
    max-height: 180px;
    background-color: #F7F7F7;
    border-radius: 15px;
    padding: 15px 15px;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    max-width: 354px;
}

.container-detalle > .detalle-producto > .producto-informacion > .producto-precio > .producto-precio-antes {
    text-align: center;
    margin: 0px;
    font-size: 12px;
    font-weight: 400;
    color: #a6a6a6;
    margin-bottom: 10px;
    margin-top: -3px;
}

.container-detalle > .detalle-producto > .producto-informacion > .producto-precio > .producto-precio-antes > span {
    text-decoration: line-through;
}

.container-detalle > .detalle-producto > .producto-informacion > .producto-precio > .producto-oferta-valida {
    text-align: center;
    margin: 0px;
    font-size: 12px;
    font-weight: 400;
    color: rgba(240, 118, 29, 1);
    margin-top: 10px;
    margin-bottom: 0px;
}

.container-detalle > .detalle-producto > .producto-informacion > .producto-precio > .producto-precio-variable {
    text-align: center;
    margin: 0px;
    font-size: 11px;
    font-weight: 400;
    color: rgba(139, 139, 139, 0.75);
    margin-top: 10px;
    margin-bottom: -5px;
}


.container-detalle > .detalle-producto > .producto-informacion > .producto-precio > .precio-card {
    height: 70px;
    background-color: #FFFFFF;
    border-radius: 15px;
    padding: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    cursor: default;
}

.container-detalle > .detalle-producto > .producto-informacion > .producto-precio > .precio-card > .precio-normal > h3 {
    font-size: 11.5px;
    font-weight: 400;
    margin: 0px;
    text-align: center;
    color: rgba(70, 71, 74, 1);
}

.container-detalle > .detalle-producto > .producto-informacion > .producto-precio > .precio-card > .precio-normal > p {
    font-size: 20px;
    font-weight: 400;
    margin: 3px 0px 0px 0px;
    text-align: center;
    color: rgba(70, 71, 74, 1);
}

.container-detalle > .detalle-producto > .producto-informacion > .producto-precio > .precio-card > .precio-promo > h3 {
    font-size: 11.5px;
    font-weight: 600;
    margin: 0px;
    text-align: center;
    color: rgba(240, 118, 29, 1);
}


.container-detalle > .detalle-producto > .producto-informacion > .producto-precio > .precio-card > .precio-promo > p {
    font-size: 20px;
    font-weight: 600;
    margin: 3px 0px 0px 0px;
    text-align: center;
    color: rgba(240, 118, 29, 1);
}

.container-detalle > .detalle-producto > .producto-informacion > .container-buttons {
    max-width: 90%;
}

.container-detalle > .detalle-producto > .producto-informacion > .container-buttons > .buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5px;
    margin-top: 10px;
}

.container-detalle > .detalle-producto > .producto-informacion > .container-buttons > .buttons button:first-child {
     order: 1;
}

.container-detalle > .detalle-producto > .producto-informacion > .container-buttons > .buttons button:nth-child(2) {
     order: 2;
}

.container-detalle > .detalle-producto > .producto-informacion > .configuration {
    display: flex;
    justify-content: space-around;
}

.container-detalle > .detalle-producto > .producto-informacion .detalle-producto {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 2px 0px;
}

.container-detalle > .detalle-producto > .producto-informacion .detalle-producto > p {
    margin: 0px;
    padding: 0px;
    font-size: 12px;
    font-weight: 500;
    color: rgba(175, 175, 175, 1);
    margin-left: 5px;
}

.container-detalle > .detalle-producto > .producto-informacion > .detalle-producto > span {
    font-size: 18px;
    color: rgba(175, 175, 175, 1);
}

/*Informacion adicional*/

.informacion-adicional-producto {
    margin-top: 50px;
    padding: 30px 25px;
    border-radius: 15px;
    background-color: white;
    margin-bottom: 25px;
    width: 100%;
    height: 500px;
    border: none;
    display: none;
}

.informacion-adicional-producto-loader {
    margin-top: 50px;
    padding: 15px 25px;
    border-radius: 15px;
    background-color: white;
    margin-bottom: 25px;
    width: 100%;
    height: 130px;
    border: none;
}

/*informacion card no existente*/

.card-no-existe-info {
    background-color: white;
    margin-top: 25px;
    border-radius: 25px;
    padding: 60px;
}

.card-no-existe-info > .card-central {
    display: flex;
    max-width: 690px;
    height: 190px;
    margin: 0 auto;
}

.card-no-existe-info > .card-central > img {
    padding: 10px 10px 1px 10px;
    margin-right: 20px;
}

.card-no-existe-info > .card-central > .card-info > div {
    display: flex;
    margin-bottom: 10px;
}

.card-no-existe-info > .card-central > .card-info > div > img {
    width: 25px;
    height: 25px;
    margin-right: 10px;
}

.card-no-existe-info > .card-central > .card-info > div > p {
    margin: 0px;
    font-weight: 400;
    font-size: 14px;
    color: rgba(128, 128, 128, 1);
}

.card-no-existe-info > .card-central > .card-info > span {
    color: rgba(128, 128, 128, 1);
    font-size: 17px;
    font-weight: 500;
}

.card-no-existe-info > .card-central > .card-info > p {
    color: rgba(128, 128, 128, 1);
    font-size: 17px;
    font-weight: 600;
}


/*card de cuotas*/

.container-cuotas {
    background-color: #F7F7F7;
    padding: 5px 20px;
    margin-top: 3px;
}

.card-cuotas {
    background-color: white;
    border-radius: 5px;
    margin: 10px 0px;
}

.card-cuotas > .imagen {
    display: flex;
    justify-content: center;
    padding: 3px 0px;
    height: 40px;
    cursor: pointer;
}

.card-cuotas > .imagen-flex {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 3px 0px;
    height: 30px;
    cursor: pointer;
}

.card-cuotas > .imagen-flex > img {
    height: 100%;
}

.card-cuotas > .cuotas {
    padding: 10px 30px;
}

.card-cuotas > .cuotas > .cuotas-card {
    background-color: #F7F7F7;
    border-radius: 15px;
}

.card-cuotas > .cuotas > .cuotas-card > div > p {
    margin: 3px 0px;
}

.card-cuotas > .cuotas > .cuotas-card > .title-cuotas {
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: center;
    background-color: rgba(19,180,188,.1);
    color: #13b4bc;
    border-radius: 15px 15px 0px 0px;
}

.card-cuotas > .cuotas > .cuotas-card > .cuotas-montos {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    color: #9d9d9d;
}

/*Card de categorias*/
.detalle-categorias {
    background-color: #FFFFFF;
    border-radius: 25px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;
    min-height: 30rem;
    padding: 2rem;
    margin-top: 5rem;
    gap: 2rem;
}

.detalle-categorias > .detalle-character {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

/*Modal Video*/

.container-video {
    height: 75vh;
    width: 75vw;
    background-color: transparent;
    margin: 5% auto;
}

.container-video > button {
  background-color: transparent;
  text-align: end;
  color: white;
  margin-left: 98%;
  font-size: 15px;
}

.iframe-video {
  width: 100%;
  height: 95%;
}


#ProductosParecidosID> p:hover {
    color: rgba(240, 118, 29, 1);
}

#preguntas {
    max-width: 1320px;
    margin: 0 auto;
}


@media only screen and (max-width: 1200px) {

    .container-detalle {
        display: grid;
        grid-template-columns: 1fr;
        gap: 15px;
        margin-top: 3vh;
    }
}

@media only screen and (max-width: 1000px) {

    .container-detalle {
        margin-top: 3vh;
    }

    .container-detalle > .detalle-producto {
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
        padding: 5px;
    }

    .container-detalle > .detalle-producto > .producto-informacion {
        padding: 5px 20px;
        padding-bottom: 20px;
        margin: 0 auto;
    }

    .container-detalle > .detalle-producto > .producto-informacion > .imagen {
        flex-direction: row;
        justify-content: space-around;
    }

    .container-detalle > .detalle-producto > .producto-informacion > p {
        text-align: center;
    }

    .container-detalle > .detalle-producto > .producto-informacion > .producto-precio {
        max-width: 100%;
    }

    .container-detalle > .detalle-producto > .producto-informacion > .container-buttons {
        max-width: 100%;
    }

    .container-detalle > .detalle-producto > .producto-informacion > .container-buttons > .buttons {
        display: flex;
        flex-direction: column;
        gap: 10px !important;
    }

    .container-detalle > .detalle-producto > .producto-informacion > .container-buttons > .buttons button:first-child {
        order: 2;
   }
   
   .container-detalle > .detalle-producto > .producto-informacion > .container-buttons > .buttons button:nth-child(2) {
        order: 1;
   }
    
    .container-detalle > .detalle-producto > .producto-informacion > .configuration {
        max-width: 100%;
    }


    .card-no-existe-info {
        padding: 20px;
    }

    .card-no-existe-info > .card-central {
        flex-direction: column;
        align-items: center;
        min-height: 450px;
    }

    .card-no-existe-info > .card-central > .card-info {
        display: flex;
        flex-direction: column;
    }

    .card-no-existe-info > .card-central > .card-info > span > .salto-linea  {
        display: none;
    }

    .card-no-existe-info > .card-central > .card-info > span {
        text-align: center;
        margin-top: 15px;
        font-size: 16px;
    }

    .card-no-existe-info > .card-central > img {
        max-width: 150px;
    }
}

@media only screen and (max-width: 510px) {
    .container-detalle > .detalle-producto > .producto-informacion > .configuration > button > .text {
        display: none;
    }

    .container-detalle > .detalle-producto > .producto-informacion {
        max-width: 380px;
    }

    .banner-producto-no-disponible {
        display: grid;
        grid-template-columns: 1fr;
        justify-items: center;
    }

    .banner-producto-no-disponible .text-detalle-producto {
        padding-top: 50px;
      }
}

@media only screen and (max-width: 365px) {
    .container-detalle > .detalle-producto > .producto-informacion {
        max-width: 330px;
    }

    
    .container-detalle > .detalle-producto > .producto-informacion > .producto-precio > .precio-card > .precio-promo > h3 {
        font-size: 10.5px;
    }
}
